import React, { useState } from "react"
import { Link } from "react-router-dom"
import Popup from "../Sign/Popup"
import { useAuth } from "../../context/AuthContext" // Add this import

export default function Hero() {
  const [showPopup, setShowPopup] = useState(false)
  const { currentUser } = useAuth() // Add this line

  const handleStartClick = (e) => {
    if (!currentUser) {
      e.preventDefault()
      setShowPopup(true)
    }
    // If user is logged in, the default link behavior will occur
  }

  return (
    <div className="bg-bg-color text-white h-screen flex items-center justify-center">
      <div className="text-center max-w-3xl px-4">
        <h1 className="text-5xl font-bold mb-6">
          Notifications, <span className="text-blue-400">extremely simple</span>
        </h1>
        <p className="text-xl mb-8">
          Send messages from your code or apps directly to Telegram and other
          platforms. Uncomplicated. Fast. Reliable.
        </p>
        <Link
          to={currentUser ? "/dashboard" : "/signup"}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg text-lg transition duration-300"
          onClick={handleStartClick}
        >
          Start for free now
        </Link>
        {/* <p className="mt-3 text-sm text-gray-400">
          14 days free. No credit card required.
        </p> */}
      </div>
      {!currentUser && (
        <Popup show={showPopup} onClose={() => setShowPopup(false)} />
      )}
    </div>
  )
}
