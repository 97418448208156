import React, { useState, useEffect } from "react"
import { useParams, Link } from "react-router-dom"
import { useAuth } from "../../context/AuthContext"

export default function Project() {
  const { projectId } = useParams()
  const { projects, alerts } = useAuth()
  const [project, setProject] = useState(null)

  useEffect(() => {
    const currentProject = projects?.find((p) => p.id === projectId)
    setProject(currentProject)
  }, [projectId, projects])

  if (!project) {
    return <div className="text-white">Loading...</div>
  }

  return (
    <div className="w-full flex flex-col items-center text-sm text-white flex-grow p-8">
      <div className="w-full max-w-4xl flex justify-start mb-6">
        <div className="p-1 px-3 bg-[#2a2a2a] border border-[#2f2f2e] rounded-lg inline-block">
          <Link to="/dashboard" className="font-bold text-neutral-200">
            ← Dashboard
          </Link>
        </div>
      </div>

      <h1 className="text-3xl font-bold mb-6">{project.name}</h1>

      <div className="w-full max-w-4xl bg-neutral-800 rounded-lg p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">Project Overview</h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-neutral-400">Telegram username:</p>
            <p>{project.receivers?.telegram?.username || "Not set"}</p>
          </div>
          <div>
            <p className="text-neutral-400">Alerts sent:</p>
            <p>
              {alerts?.filter(
                (alert) =>
                  alert.project.toLowerCase() === project.name.toLowerCase()
              ).length || 0}
            </p>
          </div>
        </div>
      </div>

      <div className="w-full max-w-4xl">
        <h2 className="text-xl font-semibold mb-4">Recent Alerts</h2>
        {alerts && alerts.length > 0 ? (
          <table className="w-full bg-neutral-800 rounded-lg overflow-hidden">
            <thead className="bg-neutral-700">
              <tr>
                <th className="p-3 text-left">Date</th>
                <th className="p-3 text-left">Message</th>
                <th className="p-3 text-left">Status</th>
              </tr>
            </thead>
            <tbody>
              {alerts
                .filter(
                  (alert) =>
                    alert.project.toLowerCase() === project.name.toLowerCase()
                )
                .map((alert, index) => (
                  <tr key={index} className="border-t border-neutral-700">
                    <td className="p-3">
                      {alert.createdAt.toDate().toLocaleString()}
                    </td>
                    <td className="p-3">{alert.text}</td>
                    <td className="p-3">
                      {alert.status && (
                        <span
                          className={`inline-block px-2 py-1 rounded text-xs font-medium 
                            ${
                              alert.status === "notified"
                                ? "bg-green-200 text-green-800"
                                : "bg-yellow-200 text-yellow-800"
                            }`}
                        >
                          {alert.status}
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <p>No alerts found for this project.</p>
        )}
      </div>
    </div>
  )
}
