import React from "react"
import "./assets/App.css"
import Dashboard from "./pages/Dashboard/Dashboard"
import Header from "./pages/Landingpage/Header"
import Hero from "./pages/Landingpage/Hero"
import Register from "./pages/Sign/Register"
import Login from "./pages/Sign/Login"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import ForgotPassword from "./pages/Sign/ForgotPassword"
import { AuthProvider } from "./context/AuthContext"
import Popup from "./pages/Sign/Popup"
import { Helmet, HelmetProvider } from "react-helmet-async"
import Footer from "./pages/Landingpage/Footer"
import { Redirect } from "react-router-dom"
import Billing from "./pages/Others/Billing"
import Support from "./pages/Others/Support"
import NewProject from "./pages/Dashboard/NewProject"
import Project from "./pages/Dashboard/Project"
import Pricing from "./components/Pricing"

function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <BrowserRouter>
          <AppContent />
        </BrowserRouter>
      </AuthProvider>
    </HelmetProvider>
  )
}

function AppContent() {
  return (
    <div className="flex flex-col min-h-screen bg-bg-color">
      <Switch>
        <Route exact path="/login">
          <Header />
          <Login />
        </Route>
        <Route exact path="/register">
          <Header />
          <Register />
        </Route>
        <Route exact path="/forgot-password">
          <Header />
          <ForgotPassword />
        </Route>
        <Route exact path="/">
          <Helmet>
            <title>Alert</title>
            <meta name="description" content="Alert" />
            <link rel="canonical" href={`https://sendalert.io`} />
          </Helmet>
          <Header />
          <Popup />
          <Hero />
          <Footer />
        </Route>
        <Route exact path="/dashboard">
          <Header />
          <Helmet>
            <title>Dashboard - SendAlert</title>
            <meta
              name="description"
              content="Access your SendAlert dashboard to manage your account and settings."
            />
            <link rel="canonical" href="https://sendalert.io/dashboard" />
          </Helmet>
          <Dashboard />
        </Route>

        <Route exact path="/dashboard/new">
          <Header />
          <NewProject />
        </Route>

        <Route path="/project/:projectId?">
          <Header />
          <Project />
        </Route>

        <Route path="/project/:projectId">
          <Header />
          <Project />
        </Route>

        <Route exact path="/support">
          <Helmet>
            <title>Support - SendAlert</title>
            <meta
              name="description"
              content="Get support for SendAlert. Contact me for any issues or questions."
            />
            <link rel="canonical" href="https://sendalert.io/support" />
          </Helmet>
          <Header />
          <Support />
        </Route>

        {/* <Route exact path="/billing">
          <Header />
          <Helmet>
            <title>Billing - SendAlert</title>
            <meta
              name="description"
              content="Manage your billing settings for SendAlert. Change your plan, cancel your subscription, or get support."
            />
            <link rel="canonical" href="https://sendalert.io/billing" />
          </Helmet>
          <Billing />
        </Route>

        <Route exact path="/pricing">
          <Header />
          <Pricing />
        </Route> */}

        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </div>
  )
}

export default App
