import React, { useState, useEffect } from "react"
import { Link, useLocation, useHistory } from "react-router-dom"
import { firestore } from "../../firebase" // Stellen Sie sicher, dass der Pfad korrekt ist
import { serverTimestamp } from "firebase/firestore"
import { useAuth } from "../../context/AuthContext" // Angenommen, Sie haben einen AuthContext
import { FaTelegram, FaCheckCircle, FaCopy } from "react-icons/fa" // Import Telegram icon
import { telegram } from "../../utils/telegram"
// Hilfsfunktion zum Parsen der Suchparameter
function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export default function NewProject() {
  const query = useQuery()
  const history = useHistory()
  const { user } = useAuth() // Holen Sie sich den aktuellen Benutzer
  const [currentStep, setCurrentStep] = useState("create")
  const [projectName, setProjectName] = useState("")
  const [error, setError] = useState(null)
  const [projectId, setProjectId] = useState(null)
  const [verificationCode, setVerificationCode] = useState("")
  const [copied, setCopied] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)
  const [verificationResult, setVerificationResult] = useState(null)
  const [telegramVerified, setTelegramVerified] = useState(false)
  const [selectedChannel, setSelectedChannel] = useState("")
  const [selectedLanguage, setSelectedLanguage] = useState("cURL")

  useEffect(() => {
    const step = query.get("step")
    const id = query.get("projectId")
    if (step && ["create", "get", "send"].includes(step)) {
      setCurrentStep(step)
    }
    if (id) {
      setProjectId(id)
      fetchProjectName(id) // Neue Funktion zum Abrufen des Projektnamens
    }
    if (!verificationCode) {
      setVerificationCode(generateVerificationCode())
    }

    // Check if user's Telegram is already verified
    const checkTelegramVerification = async () => {
      if (user && user.id) {
        const userDoc = await firestore.collection("users").doc(user.id).get()
        const userData = userDoc.data()
        setTelegramVerified(userData?.telegram?.verified || false)
      }
    }
    checkTelegramVerification()
  }, [query, verificationCode, user])

  // Neue Funktion zum Abrufen des Projektnamens
  const fetchProjectName = async (projectId) => {
    if (user && user.id) {
      try {
        const projectDoc = await firestore
          .collection("users")
          .doc(user.id)
          .collection("projects")
          .doc(projectId)
          .get()

        if (projectDoc.exists) {
          const projectData = projectDoc.data()
          setProjectName(projectData.name)
        }
      } catch (error) {
        console.error("Error fetching project name:", error)
      }
    }
  }

  const generateVerificationCode = () => {
    return Math.floor(100000 + Math.random() * 900000).toString()
  }

  const steps = [
    { id: "create", label: "Create project" },
    { id: "get", label: "Get notifications" },
    { id: "send", label: "Send notifications" },
  ]

  const createProjectDocument = async () => {
    setError(null)
    if (!user) {
      setError("You must be logged in to create a project.")
      return null
    }

    const projectData = {
      userId: user.id,
      createdAt: serverTimestamp(),
      name: projectName,
      status: "created",
      verificationCode: verificationCode, // Add verification code to project data
    }

    try {
      const projectsCollectionRef = firestore
        .collection("users")
        .doc(user.id)
        .collection("projects")

      const docRef = await projectsCollectionRef.add(projectData)

      return docRef.id
    } catch (error) {
      console.error("Error creating project:", error)
      setError("Error while creating project. Please try again.")
      return null
    }
  }

  const handleCreateProject = async (e) => {
    e.preventDefault()

    if (!projectName.trim()) {
      setError("Project name cannot be empty.")
      return
    }

    // Check if a project with the same name already exists
    try {
      const projectsCollectionRef = firestore
        .collection("users")
        .doc(user.id)
        .collection("projects")

      const existingProjects = await projectsCollectionRef
        .where("name", "==", projectName)
        .get()

      if (!existingProjects.empty) {
        setError(
          "A project with this name already exists. Please choose a different name."
        )
        return
      }

      const newProjectId = await createProjectDocument()

      if (newProjectId) {
        //console.log("Project created with ID:", newProjectId)
        setProjectId(newProjectId)
        history.push(`/dashboard/new?step=get&projectId=${newProjectId}`)
      }
    } catch (error) {
      console.error("Error checking for existing project:", error)
      setError(
        "An error occurred while creating the project. Please try again."
      )
    }
  }

  const handleCopyVerificationCode = () => {
    navigator.clipboard.writeText(verificationCode)
    setCopied(true)
  }

  const handleCopyCode = () => {
    const code = getCodeExample(selectedLanguage)
    navigator.clipboard.writeText(code)
  }

  const verifyNotification = async () => {
    setIsVerifying(true)
    setVerificationResult(null)

    try {
      const messagesRef = firestore.collection("messages")
      const querySnapshot = await messagesRef
        .where("text", "==", verificationCode)
        .get()

      // Simuliere eine Verzögerung von mindestens einer Sekunde
      await new Promise((resolve) => setTimeout(resolve, 1000))

      if (!querySnapshot.empty) {
        const matchingDoc = querySnapshot.docs[0].data()
        //console.log("Matching message found:", matchingDoc)

        const telegramId = matchingDoc.from.id
        const telegramUsername = matchingDoc.from.username

        if (user && user.id) {
          const userRef = firestore.collection("users").doc(user.id)
          const updateData = {
            "telegram.id": telegramId,
            "telegram.verified": true,
          }

          if (telegramUsername) {
            updateData["telegram.username"] = telegramUsername
          }

          await userRef.update(updateData)
          //console.log("User document updated with Telegram information")
        } else {
          console.error("User not found or not logged in")
        }

        setVerificationResult("success")
        setTelegramVerified(true)
      } else {
        //console.log("No matching message found")
        setVerificationResult("failure")
        telegram(
          "NewProject.js, Verification failed. No matching message found"
        )
      }
    } catch (error) {
      console.error("Error verifying notification:", error)
      setVerificationResult("error")
    } finally {
      setIsVerifying(false)
    }
  }

  const handleStepClick = (stepId) => {
    // Only allow going back to previous steps
    const currentStepIndex = steps.findIndex((step) => step.id === currentStep)
    const clickedStepIndex = steps.findIndex((step) => step.id === stepId)

    if (clickedStepIndex <= currentStepIndex) {
      setCurrentStep(stepId)
      history.push(
        `/dashboard/new?step=${stepId}${
          projectId ? `&projectId=${projectId}` : ""
        }`
      )
    }
  }

  const handleNextStep = async () => {
    if (telegramVerified && user && user.id && projectId) {
      try {
        const userDoc = await firestore.collection("users").doc(user.id).get()
        const userData = userDoc.data()

        // Aktualisieren Sie das Projekt-Dokument mit den Telegram-Daten
        await firestore
          .collection("users")
          .doc(user.id)
          .collection("projects")
          .doc(projectId)
          .update({
            receivers: {
              telegram: {
                name: "telegram",
                chatId: userData.telegram.id,
                username: userData.telegram.username,
              },
            },
            status: "finished",
          })

        history.push(`/dashboard/new?step=send&projectId=${projectId}`)
      } catch (error) {
        console.error("Error updating project:", error)
        setError("Error updating project. Please try again.")
      }
    }
  }

  const getCodeExample = (lang) => {
    const baseUrl = "https://api.sendalert.io/v1/alert"
    const apiKey = user?.api_key
    const project = projectName

    switch (lang) {
      case "cURL":
        return `curl -X POST ${baseUrl} \\
    -H "Content-Type: application/json" \\
    -d '{
    "key": "${apiKey}",
    "project": "${project}",
    "text": "Your message"
    }'`

      case "PHP":
        return `<?php
$url = '${baseUrl}';
$data = array(
    'key' => '${apiKey}',
    'project' => '${project}',
    'text' => 'Your message'
);

$options = array(
    'http' => array(
        'header'  => "Content-type: application/json\r\n",
        'method'  => 'POST',
        'content' => json_encode($data),
    ),
);
$context  = stream_context_create($options);
$result = file_get_contents($url, false, $context);
if ($result === FALSE) { /* Handle error */ }

var_dump($result);`

      case "JavaScript":
        return `const axios = require('axios');

const data = {
  key: '${apiKey}',
  project: '${project}',
  text: 'Your message'
};

axios.post('${baseUrl}', data)
  .then(response => {
    console.log(response.data);
  })
  .catch(error => {
    console.error(error);
  });`

      case "Python":
        return `import requests
import json

url = '${baseUrl}'
data = {
    'key': '${apiKey}',
    'project': '${project}',
    'text': 'Your message'
}

headers = {'Content-Type': 'application/json'}

response = requests.post(url, data=json.dumps(data), headers=headers)
print(response.json())`

      default:
        return ""
    }
  }

  return (
    <div className="w-full flex flex-col items-center text-sm text-white flex-grow">
      <div className="max-w-lg w-full h-full">
        <div className="mt-6 p-1 px-3 bg-[#2a2a2a] border border-[#2f2f2e] rounded-lg inline-block">
          <Link to="/dashboard" className="font-bold text-neutral-200">
            ← Dashboard
          </Link>
        </div>

        <div className="w-full mx-auto mt-8 mb-6">
          <ul className="flex justify-between items-center">
            {steps.map((step) => (
              <li
                key={step.id}
                className={`flex items-center ${
                  step.id !== "create" ? "cursor-pointer" : ""
                }`}
                onClick={() => step.id !== "create" && handleStepClick(step.id)}
              >
                <span
                  className={`w-4 h-4 rounded-full mr-2 ${
                    currentStep === step.id ? "bg-blue-500" : "bg-neutral-700"
                  }`}
                ></span>
                <span
                  className={
                    currentStep === step.id
                      ? "text-neutral-300"
                      : "text-neutral-500"
                  }
                >
                  {step.label}
                </span>
              </li>
            ))}
          </ul>
        </div>

        <div className="w-full bg-neutral-800 border border-[#2f2f2e] rounded-2xl">
          <h2 className="p-4 text-base font-bold border-b border-[#2f2f2e] pb-3">
            {currentStep === "create" && "Create a new project"}
            {currentStep === "get" && (
              <>
                Get notified
                <span className="text-xs font-normal text-neutral-400 block mt-1">
                  Choose and verify your notification channel.
                </span>
              </>
            )}
            {currentStep === "send" && (
              <>
                Send notifications
                <span className="text-xs font-normal text-neutral-400 block mt-1">
                  Implement your notification logic.
                </span>
              </>
            )}
          </h2>
          {currentStep === "create" && (
            <form className="p-4" onSubmit={handleCreateProject}>
              {error && <p className="text-red-500 mb-4">{error}</p>}
              <label
                htmlFor="projectName"
                className="block font-medium text-neutral-300 mb-2"
              >
                Project Name
              </label>
              <input
                type="text"
                id="projectName"
                className="bg-neutral-700 text-white mb-6 rounded-md border border-neutral-600 focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                placeholder="My Awesome Project"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
              />

              <button
                type="submit"
                className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300"
              >
                Create project
              </button>
            </form>
          )}
          {currentStep === "get" && (
            <div className="p-4">
              <div className="mb-6">
                <label className="block font-medium text-neutral-300 mb-2">
                  1. Choose a channel
                </label>
                <div className="flex space-x-4">
                  <button
                    className={`h-48 w-44 bg-neutral-700 hover:bg-neutral-600 text-white font-bold py-4 px-4 rounded-md transition duration-300 flex flex-col items-center justify-center relative`}
                    onClick={() =>
                      setSelectedChannel(
                        selectedChannel === "telegram" ? "" : "telegram"
                      )
                    }
                  >
                    <div
                      className={`absolute top-2 right-2 w-4 h-4 rounded-full border-2 ${
                        selectedChannel === "telegram"
                          ? "bg-blue-500 border-blue-500"
                          : "border-neutral-500"
                      }`}
                    ></div>
                    <FaTelegram className="text-6xl mb-2" />
                    <span className="flex items-center">
                      Telegram{" "}
                      {telegramVerified && (
                        <FaCheckCircle className="text-green-500 ml-1" />
                      )}
                    </span>
                    <span className="text-xs text-blue-400">
                      {telegramVerified
                        ? `@${user?.telegram?.username}`
                        : "(not verified yet)"}
                    </span>
                  </button>
                  <div className="h-48 w-44 bg-neutral-800 text-neutral-500 font-bold py-4 px-4 rounded-md flex flex-col items-center justify-center transition duration-300 hover:bg-neutral-700">
                    <span className="text-center">
                      More channels coming soon
                    </span>
                  </div>
                </div>
              </div>

              {selectedChannel === "telegram" && (
                <>
                  {!telegramVerified ? (
                    <ol className="mb-4 space-y-4">
                      <li>
                        <span className="block mb-2 font-medium">
                          2. Copy code:
                        </span>
                        <div className="bg-neutral-700 p-2 rounded-md flex items-center justify-between">
                          <code className="text-lg">{verificationCode}</code>
                          <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded-md transition duration-300 text-xs"
                            onClick={handleCopyVerificationCode}
                          >
                            {copied ? "Copied!" : "Copy"}
                          </button>
                        </div>
                      </li>
                      <li>
                        <span className="block mb-2 font-medium">
                          3. Send to:
                        </span>
                        <button
                          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 text-sm flex items-center"
                          onClick={() =>
                            window.open(
                              "http://t.me/sendalertapp_bot",
                              "_blank"
                            )
                          }
                        >
                          <FaTelegram className="mr-2" />
                          @sendalertapp_bot
                        </button>
                      </li>
                      <li>
                        <span className="block mb-2 font-medium">
                          4. Check verification:
                        </span>
                        <button
                          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 flex items-center justify-center"
                          onClick={verifyNotification}
                          disabled={
                            isVerifying || verificationResult === "success"
                          }
                        >
                          {isVerifying ? (
                            <span className="flex items-center">
                              <svg
                                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                              Verifying...
                            </span>
                          ) : verificationResult === "success" ? (
                            <span className="flex items-center">
                              <FaCheckCircle className="mr-2" />
                              Verified!
                            </span>
                          ) : (
                            "Verify Notification"
                          )}
                        </button>
                      </li>
                    </ol>
                  ) : (
                    <button
                      className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300"
                      onClick={handleNextStep} // Ändern Sie dies von history.push zu handleNextStep
                    >
                      Go to Next Step
                    </button>
                  )}
                  {verificationResult && verificationResult !== "success" && (
                    <p
                      className={`mt-2 ${
                        verificationResult === "failure"
                          ? "text-red-500"
                          : "text-yellow-500"
                      }`}
                    >
                      {verificationResult === "failure"
                        ? "Verification failed. Please try again."
                        : "An error occurred during verification."}
                    </p>
                  )}
                </>
              )}
            </div>
          )}
          {currentStep === "send" && (
            <div className="p-4">
              <p className="mb-4">
                You can send notifications using one of the following methods:
              </p>
              <div className="flex space-x-2 mb-4">
                {["cURL", "PHP", "JavaScript", "Python"].map((lang) => (
                  <button
                    key={lang}
                    className={`px-3 py-1 rounded-md text-sm font-medium ${
                      selectedLanguage === lang
                        ? "bg-blue-500 text-white"
                        : "bg-neutral-700 text-neutral-300 hover:bg-neutral-600"
                    }`}
                    onClick={() => setSelectedLanguage(lang)}
                  >
                    {lang}
                  </button>
                ))}
              </div>
              <div className="bg-neutral-900 p-4 rounded-md">
                <div className="flex justify-end items-center mb-2">
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded-md transition duration-300 text-xs flex items-center"
                    onClick={handleCopyCode}
                  >
                    <FaCopy className="mr-1" /> Copy Code
                  </button>
                </div>
                <pre className="overflow-x-auto text-sm">
                  <code>{getCodeExample(selectedLanguage)}</code>
                </pre>
              </div>
              <button
                className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 mt-4"
                onClick={() => history.push("/dashboard")}
              >
                Finish Setup
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
