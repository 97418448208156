import React, { useState, useEffect } from "react"
import { auth, GoogleAuthProvider, firestore } from "../firebase"

import { telegram } from "../utils/telegram"

const AuthContext = React.createContext()

export function useAuth() {
  return React.useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [projects, setProjects] = useState(null)
  const [alerts, setAlerts] = useState(null) // New state for alerts

  async function configUser(user) {
    // const configRef = firestore.collection("config").doc("default")
    // const configDoc = await configRef.get()

    // let free_balance = 8

    // if (configDoc.exists) {
    //   const configData = configDoc.data()
    //   free_balance =
    //     configData.freeBalance !== undefined
    //       ? configData.freeBalance
    //       : free_balance
    // }

    const userRef = firestore.collection("users").doc(user.uid)

    const createdAt = new Date()
    const referrer = localStorage.getItem("referrer")
    const searchKeyword = localStorage.getItem("search_keyword")

    const generateKey = () => {
      let key = ""
      while (key.length < 40) {
        key += Math.random().toString(36).substr(2)
      }
      return key.substr(0, 40)
    }

    const userData = {
      email: user.email,
      api_key: generateKey(),
      balance: 0,
      createdAt: createdAt,
      referrer: referrer,
      plan: "free",
      group: "A",
    }

    if (searchKeyword) {
      userData.search_keyword = searchKeyword
    }

    await userRef.set(userData)

    telegram("New user created: " + userData.email)

    window?.datafast("signup", { email: userData.email })

    return userData
  }

  async function signupWithEmail(email, password) {
    try {
      const userCredential = await auth.createUserWithEmailAndPassword(
        email,
        password
      )
      const user = userCredential.user
      await configUser(user)
      return "success"
    } catch (error) {
      telegram(
        "frontend, AuthContext, signupWithEmail, error: " + error.message
      )
      throw new Error("Failed to create an account")
    }
  }

  async function signupWithGoogle() {
    try {
      const provider = new GoogleAuthProvider()
      const userCredential = await auth.signInWithPopup(provider)
      const user = userCredential.user
      const isNewUser = userCredential.additionalUserInfo?.isNewUser

      if (isNewUser) {
        const userData = await configUser(user)
        return { state: "new user", group: userData.group }
      } else {
        const userDoc = await firestore.collection("users").doc(user.uid).get()
        const userData = userDoc.data()
        return { state: "existing user", group: userData.group }
      }
    } catch (error) {
      // Handle error
      return { state: "error", error: error.message }
    }
  }

  function login(email, password) {
    try {
      telegram("User login: " + email)
      return auth.signInWithEmailAndPassword(email, password)
    } catch (error) {
      telegram("frontend, AuthContext, login, error: " + error.message)
    }
  }

  const logout = async () => {
    await auth.signOut()
    setCurrentUser(null)
    setUser(null)
    setProjects(null)
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user)
      setLoading(false)
    })
    return unsubscribe
  }, [])

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const usersRef = firestore.collection("users").doc(currentUser.uid)

        const unsubscribe = usersRef.onSnapshot(async (snapshot) => {
          if (snapshot.exists) {
            const userData = snapshot.data()
            const userId = snapshot.id
            const paymentsRef = usersRef.collection("payments")
            const collectionsPayments = await paymentsRef.get()
            const collectionsData = collectionsPayments.docs.map((doc) =>
              doc.data()
            )
            const sumOfCredits = collectionsData
              .filter((item) => item.status === "succeeded")
              .flatMap((item) => item.items)
              .map((item) => parseInt(item.price.metadata.credits))
              .filter((credits) => !isNaN(credits))
              .reduce((accumulator, current) => accumulator + current, 0)

            setUser({ id: userId, ...userData, credits_bought: sumOfCredits })
          }
        })

        return () => unsubscribe()
      } catch (error) {
        telegram("frontend, AuthContext, fetchUser, error: " + error.message)
      }
    }

    if (currentUser) {
      fetchUser()
    }
  }, [currentUser])

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        if (!currentUser) {
          return
        }

        const projectsRef = firestore
          .collection("users")
          .doc(currentUser.uid)
          .collection("projects")
          .orderBy("createdAt", "desc")
          .limit(200)

        const unsubscribe_projects = projectsRef.onSnapshot(
          async (querySnapshot) => {
            const projectsData = []

            querySnapshot.forEach((doc) => {
              const projectData = doc.data()
              const projectId = doc.id
              projectsData.push({ id: projectId, ...projectData })
            })

            setProjects(projectsData)
          }
        )

        return () => unsubscribe_projects()
      } catch (error) {
        telegram(
          "frontend, AuthContext, fetchProjects, error: " + error.message
        )
      }
    }

    const fetchAlerts = async () => {
      try {
        if (!currentUser) {
          return
        }

        const alertsRef = firestore
          .collection("users")
          .doc(currentUser.uid)
          .collection("alerts")
          .orderBy("createdAt", "desc")
          .limit(200)

        const unsubscribe_alerts = alertsRef.onSnapshot(
          async (querySnapshot) => {
            const alertsData = []

            querySnapshot.forEach((doc) => {
              const alertData = doc.data()
              const alertId = doc.id
              alertsData.push({ id: alertId, ...alertData })
            })

            setAlerts(alertsData)
          }
        )

        return () => unsubscribe_alerts()
      } catch (error) {
        telegram("frontend, AuthContext, fetchAlerts, error: " + error.message)
      }
    }

    if (currentUser) {
      fetchProjects()
      fetchAlerts() // Call fetchAlerts along with fetchProjects
    }
  }, [currentUser])

  const postsRef = firestore.collection("posts")
  const postsQuery = postsRef.orderBy("createdAt")
  const [posts, setPosts] = useState([])

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const snapshot = await postsQuery.get()
        const postsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))

        setPosts(postsData)
        setLoading(false)
      } catch (error) {
        telegram("frontend, AuthContext, fetchPosts, error: " + error.message)
      }
    }

    fetchPosts()
  }, [currentUser])

  const value = {
    currentUser,
    signupWithGoogle,
    signupWithEmail,
    login,
    logout,
    resetPassword,
    user,
    projects,
    alerts, // Add alerts to the context value
    posts,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
