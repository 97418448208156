import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import { useAuth } from "../../context/AuthContext"

export default function Dashboard() {
  const { projects, alerts, user } = useAuth()

  // Calculate account age and remaining free trial days
  const accountInfo = useMemo(() => {
    if (!user || !user.createdAt || user.plan !== "free") return null

    const now = new Date()
    const createdAt = user.createdAt.toDate()
    const diffTime = Math.abs(now - createdAt)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    const remainingDays = Math.max(15 - diffDays, 0)

    return { diffDays, remainingDays }
  }, [user])

  // Calculate notifications for each project and total alerts in the last 24 hours
  const { projectNotifications, totalAlerts } = useMemo(() => {
    if (!projects || !alerts) {
      return { projectNotifications: {}, totalAlerts: 0 }
    }

    const now = new Date()
    const oneDayAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000)

    const projectNotifications = projects.reduce((acc, project) => {
      const count = alerts.filter(
        (alert) =>
          alert.project.toLowerCase() === project.name.toLowerCase() &&
          alert.createdAt.toDate() > oneDayAgo
      ).length

      acc[project.id] = count
      return acc
    }, {})

    const totalAlerts = Object.values(projectNotifications).reduce(
      (sum, count) => sum + count,
      0
    )

    return { projectNotifications, totalAlerts }
  }, [projects, alerts])

  // Get recent alerts for each project
  const projectRecentAlerts = useMemo(() => {
    if (!alerts || !projects) return {}

    return projects.reduce((acc, project) => {
      acc[project.id] = alerts
        .filter(
          (alert) => alert.project.toLowerCase() === project.name.toLowerCase()
        )
        .sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
        .slice(0, 25) // Show only 25 most recent alerts per project
      return acc
    }, {})
  }, [alerts, projects])

  return (
    <div className="w-full flex flex-col items-center bg-bg-color text-white flex-grow">
      {/* {accountInfo && (
        <div className="w-full bg-gray-700 p-4 text-center">
          <p>
            You have {accountInfo.remainingDays} days left in your free trial.
            <Link
              to="/pricing"
              className="ml-2 underline font-bold text-blue-400"
            >
              Upgrade now for early adopter price
            </Link>
          </p>
        </div>
      )} */}

      <div className="max-w-6xl mx-auto px-3 md:px-8 py-4 h-16 flex justify-between w-full">
        <span className="text-neutral-400">
          You have{" "}
          <span className="font-bold text-white">{projects?.length}</span>{" "}
          projects and{" "}
          <span className="font-bold text-white">{totalAlerts}</span> alerts in
          the last 24 hours
        </span>
        <Link
          to="/dashboard/new"
          className="flex items-center space-x-2 px-2 bg-neutral-800 hover:bg-neutral-700 rounded-lg p-1"
        >
          <span className="text-base text-neutral-300">+</span>
          <span className="text-sm md:text-base font-bold text-neutral-300">
            Project
          </span>
        </Link>
      </div>

      <div className="max-w-6xl w-full px-3 md:px-8 py-4">
        {projects && projects?.length > 0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {projects.map((project) => (
              <div
                key={project.id}
                className="bg-neutral-800 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
              >
                <h3 className="text-xl font-semibold mb-3">{project.name}</h3>
                <p className="text-md text-neutral-400 mb-4">
                  {project.description}
                </p>
                <div className="flex justify-between items-center mb-4">
                  <span className="text-sm font-medium text-neutral-400">
                    <span className="text-white font-bold">
                      {projectNotifications[project.id]}
                    </span>{" "}
                    alerts in the last 24h
                  </span>
                  <Link
                    to={
                      project.status === "created"
                        ? `/dashboard/new?step=get&projectId=${project.id}`
                        : `/project/${project.id}`
                    }
                    className="text-sm text-blue-400 hover:text-blue-300 transition-colors duration-300"
                  >
                    {project.status === "created"
                      ? "Continue Setup"
                      : "View Project"}
                  </Link>
                </div>

                {/* Recent Alerts for this project */}
                <div className="mt-4">
                  <h4 className="text-md font-semibold mb-4">Recent Alerts</h4>
                  {projectRecentAlerts[project.id]?.length > 0 ? (
                    <>
                      <table className="w-full text-sm">
                        <thead className="bg-neutral-700">
                          <tr>
                            <th className="p-3 text-left">Date</th>
                            <th className="p-3 text-left">Message</th>
                            <th className="p-3 text-left">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {projectRecentAlerts[project.id].map(
                            (alert, index) => (
                              <tr
                                key={index}
                                className="border-t border-neutral-700"
                              >
                                <td className="p-3">
                                  {alert.createdAt.toDate().toLocaleString()}
                                </td>
                                <td className="p-3">{alert.text}</td>
                                <td className="p-3">
                                  {alert.status && (
                                    <span
                                      className={`inline-block px-2 py-1 rounded text-xs font-medium 
                                      ${
                                        alert.status === "notified"
                                          ? "bg-green-200 text-green-800"
                                          : "bg-yellow-200 text-yellow-800"
                                      }`}
                                    >
                                      {alert.status}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      {projectRecentAlerts[project.id].length === 25 && (
                        <Link
                          to={`/project/${project.id}`}
                          className="block mt-4 text-sm text-blue-400 hover:text-blue-300 transition-colors duration-300"
                        >
                          View more
                        </Link>
                      )}
                    </>
                  ) : (
                    <p className="text-sm text-neutral-400">
                      No recent alerts for this project.
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-neutral-400">
            No projects found. Create a new project to get started!
          </p>
        )}
      </div>
    </div>
  )
}
