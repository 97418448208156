import React from "react"
import { Link } from "react-router-dom"
import { useAuth } from "../../context/AuthContext"
import { useHistory } from "react-router-dom"

export default function Footer() {
  const { currentUser, logout } = useAuth()
  const history = useHistory()
  const { signupWithGoogle } = useAuth()

  const handleLogout = async () => {
    await logout()
    history.push("/")
  }

  async function handleGoogleLogin() {
    const userState = await signupWithGoogle()
    if (userState === "new user") {
      history.push("/pricing")
    } else if (userState === "existing user") {
      history.push("/dashboard")
    } else {
      //telegram("handleGoogleLogin: " + userState)
    }
  }

  return (
    <div className="my-12 w-full flex flex-col items-center">
      <div className="w-11/12 2xl:w-10/12 mt-12 ">
        <div className="mx-auto w-full">
          <div className="md:flex md:justify-between">
            <div className="mb-6 md:mb-0 ">
              <Link to="/" className="flex items-center">
                <span className="text-base md:text-lg font-bold text-neutral-300">
                  🔔 SendAlert
                </span>
              </Link>
            </div>
            <div className="grid grid-cols-2 gap-8 sm:gap-6">
              <div>
                <h2 className="mb-6 text-sm font-semibold uppercase text-white">
                  LINKS
                </h2>
                <ul className="text-gray-400 font-medium">
                  <li className="mb-4">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  {/* <li className="mb-4">
                    <Link to="/billing">Billing</Link>
                  </li> */}
                  <li className="mb-4">
                    <Link to="/support">Support</Link>
                  </li>
                  <li className="mb-4">
                    {currentUser ? (
                      <button onClick={handleLogout}>Logout</button>
                    ) : (
                      <button onClick={handleGoogleLogin}>Log in</button>
                    )}
                  </li>
                  {/* <li className="mb-4">
                    {currentUser && <Link to="/pricing">Pricing</Link>}
                  </li> */}
                </ul>
              </div>

              <div>
                <h2 className="mb-6 text-sm font-semibold uppercase text-white">
                  MORE
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-4">
                    <a
                      href="https://interiorai.com/?ref=clarityai"
                      className="hover:underline"
                    >
                      Interior AI
                    </a>
                  </li>

                  <li className="mb-4">
                    <a
                      href="https://x.com/philz1337x"
                      className="hover:underline"
                    >
                      X / Twitter
                    </a>
                  </li>

                  {/* <li className="mb-4">
                    <Link to="/anime-image-upscaling">
                      Anime Image Upscaling
                    </Link>
                  </li> */}

                  {/* <li>
                    <a
                      href="https://wallpaperai.co/?ref=clarityai"
                      className="hover:underline"
                    >
                      Wallpaper AI
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
